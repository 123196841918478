import styled from "styled-components";
import tw from "tailwind-styled-components";

const StyledParagraphBox = styled.div`
  @media only screen and (max-width:1366px) {
    width: 100%;
  }
`;

export const ParagraphBox = tw(StyledParagraphBox)`
  block
  pr-[44px]
`;
