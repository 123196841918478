import styled from "styled-components";
import tw from "tailwind-styled-components";

const StyledParagraphContainer = styled.div`
  max-width: 1320px;

  @media only screen and (max-width:1366px) {
    max-width: 662px;
  }
  @media only screen and (max-width:1024px) {
    max-width: 540px;
  }
  @media only screen and (max-width:768px) {
    max-width: 100%;
  }
`;

export const ParagraphContainer = tw(StyledParagraphContainer)`
  mx-auto
  my-[6.75rem]

  md:px-[22px]
  md:my-[4.5rem]
  sm:my-[2.75rem]
`;
