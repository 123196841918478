import tw from "tailwind-styled-components";
import styled from "styled-components";

const StyledPageHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ParagraphFlexContainerBox = tw(StyledPageHeading)`
`;
