import styled from "styled-components";
import tw from "tailwind-styled-components";

const StyledParagraphHeaderBox = styled.div`
  max-width: 990px;

  @media only screen and (max-width:1366px) {
    max-width: 662px;
  }
  
  @media only screen and (max-width:1024px) {
    max-width: 540px;
  }

  @media only screen and (max-width:768px) {
    max-width: 100%;
  }
`;

export const ParagraphHeaderBox = tw(StyledParagraphHeaderBox)`
  mb-[4.5rem]

  md:mb-[2.75rem]
`;
