import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";
import { Paragraphs } from "@containers/paragraphs";
import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { CompactHeader } from "@brainfinance/icash-component-library";
import imgHeader from "@images/fullscreen-background.jpg";

const ResponsibleLending = () => (
  <PageLayout>
    <SEO title="Responsible lending" />
    <CompactHeader src={imgHeader}>
      <h1 className="global--page-heading mb-[1.75rem]">Responsible lending</h1>
      <p className="global--paragraph text-interface-300">
        At Finabanx our integrity and reputation depend heavily on our own
        ability to act ethically and honestly. We take this responsibility very
        seriously. In order to guide our employees and directors in the highest
        standards of ethical business activities, We have developed the
        following Code of Conduct. This Code of Conduct is a collection of rules
        and principles based on responsible lending practices currently present
        in Europe and Australia, and with an underlying fundamental
        understanding that We are individually and collectively responsible for
        conducting business ethically and with integrity.
      </p>
    </CompactHeader>
    <Box className="h-[1px]" />
    <Paragraphs
      title="Code of conduct"
      gray="conduct"
      description="Sed ac feugiat lectus, a maximus ante. Donec imperdiet risus id dolor eleifend, a auctor ipsum fringilla. Fusce mollis facilisis ligula, non porta odio tristique ut. Nullam eget nisi non justo hendrerit mattis."
      contentData={[
        {
          title: "Conformity",
          description:
            "We are fully licensed in every province of operation. We conform to all rules and regulations pertaining to our regulatory environment. We are fully engaged to offering our client’s credit solutions that totally comply with applicable federal and provincial laws and We will not take any actions that conflict with these regulations. This engagement applies to both the loan underwriting as well as any collection operations.",
        },
        {
          title: "Transparency",
          description:
            "In full compliance with applicable rules and regulations, We provide clear and comprehensive information about all rates, fees, and any other costs. We will not directly or indirectly mislead, manipulate or fraudulently influence our clients. Our employees will offer transparent and honest answers to any questions regarding the fees associated with our loans, or the obtaining of a loan.",
        },
        {
          title: "Security",
          description:
            "We take all reasonable measures to protect our client’s data and personal information. We will never resell our client’s personal information. Our online loan applications are conducted and processed in a highly secured environment.",
        },
        {
          title: "Sustainability",
          description:
            "We, in good faith and with integrity, will only provide loans that are within our client’s capacity for repayment. Using due care and diligence, and exercising our best independent judgment, We will provide loans that are inside of this repayment capability.",
        },
        {
          title: "Integrity",
          description: (
            <>
              It is our pledge to taking a cautious and conscientious approach
              to every decision regarding lending practices. This Code of
              Conduct will be at the forefront of our business decisions.
              <br />
              <br />
              We believe that everyone deserves access to ethical and prompt
              financial services and We will work diligently towards that end.
              <br />
              <br />
              If you have any questions or comments regarding our Code of
              Conduct, please feel free to email us at{" "}
              <a
                className="text-primary hover:text-primary-emphasis font-500"
                href={"mailto:hello@finabanx.com"}
              >
                hello@finabanx.com
              </a>
              . We will be more than happy to hear from you.
            </>
          ),
        },
      ]}
    />
  </PageLayout>
);

export default ResponsibleLending;
