import { GrayPhrase, Paragraph, Subheading } from "@components/typography";
import * as React from "react";

import { ParagraphBox, ParagraphFlexContainerBox } from "../styles";

const PARAGRAPHS = [
  {
    title: "Faxless payday loans in Alberta",
    description:
      "If you're a resident of Alberta, your faxless payday loan application is processed in real-time, and we do not need you to come into an office to drop off documents or sign any paperwork. We cut out the red-tape: no faxing, no mailing, no in-person signing. The internet makes everything so much easier!",
  },
  {
    title: "Money loans in Alberta",
    description: (
      <>
        Residents of Alberta who apply for a money loan online, have our
        commitment to being the best payday loan company by acting responsibly
        and ethically. There are no hidden fees, and the loan details are
        explained in clear, understandable terms.
        <br />
        <br />
        Our Customer Experience Team is available to answer any questions seven
        days a week. iCASH is licensed to operate throughout Alberta.
      </>
    ),
  },
  {
    title: "Same day loans in Alberta",
    description:
      "In the majority of cases, when you apply for a loan paid the same day in Alberta, our sophisticated decision-making system allows you to get approval within 2 minutes. For the majority of applicants, the funds will be available on the same day.",
  },
  {
    title: "Safe loan company in Alberta",
    description: (
      <>
        iCASH has invested in the latest 256-bit encryption technology to make
        sure that when you apply for a loan online in Alberta, your application
        and all your data is 100% safe. We have established the highest
        principles and standards that are in line with the industry's codes of
        ethics.
        <br />
        <br />
        We are making sure that residents in Alberta has access to safe, smart,
        and simple solutions when they need an instant payday loan.
      </>
    ),
  },
];

export const ParagraphGridContainer = () => (
  <ParagraphFlexContainerBox>
    {PARAGRAPHS.map((paragraph, ndx) => (
      <ParagraphBox className="mb-[4.5rem] items-start lg:mb-[4rem] md:mb-[2.75rem]" key={ndx}>
        <Subheading className="mb-[1.5rem]">{paragraph.title}</Subheading>
        <Paragraph>
          <GrayPhrase>{paragraph.description}</GrayPhrase>
        </Paragraph>
      </ParagraphBox>
    ))}
  </ParagraphFlexContainerBox>
);
